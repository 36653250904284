/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./complete.vue?vue&type=template&id=a6ba572e&scoped=true"
import script from "./complete.vue?vue&type=script&lang=js"
export * from "./complete.vue?vue&type=script&lang=js"
import style0 from "./complete.vue?vue&type=style&index=0&id=a6ba572e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6ba572e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextButton: require('/code/src/components/button/TextButton.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,TagSelect: require('/code/src/components/widgets/TagSelect.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default})
