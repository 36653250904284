import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm$data, _vm$data2, _vm$data4, _vm$data5, _vm$data7, _vm$data8, _vm$data9;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('ContentArea', {
    staticClass: "content-area d-flex flex-column align-center justify-center"
  }, [_c('div', {
    staticClass: "rounded-circle primary d-flex",
    staticStyle: {
      "width": "48px",
      "height": "48px"
    }
  }, [_c('div', {
    staticStyle: {
      "margin": "10px"
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "28",
      "color": "color-surface"
    }
  }, [_vm._v("mdi-check")])], 1)]), _vm._v(" "), _c('h5', {
    staticClass: "mt-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('success.createCampaign')) + "\n    ")]), _vm._v(" "), !_vm.canCreateOrder ? _c('div', {
    staticClass: "text-body-1 mt-4"
  }, [_vm._v("\n      " + _vm._s(_vm.data.isDigitalGift ? _vm.$t('extension.waitingApproveWowLinkWithCampaign') : _vm.$t('extension.waitingApproveDeliveryWithCampaign')) + "\n    ")]) : _vm._e(), _vm._v(" "), !((_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.isDigitalGift) && !_vm.existContacts ? [(_vm$data2 = _vm.data) !== null && _vm$data2 !== void 0 && _vm$data2.campaignId ? _c('TextButton', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.$t('action.doCheckCampaignDetail')
    },
    on: {
      "click": function click($event) {
        var _vm$data3;
        return _vm.$open("/campaign/".concat((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.campaignId));
      }
    }
  }) : _vm._e()] : [_c(VCard, {
    staticClass: "mt-6 pa-6",
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [(_vm$data4 = _vm.data) !== null && _vm$data4 !== void 0 && _vm$data4.isDigitalGift ? [_vm.existContacts ? _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": 1
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('extension.wowLinkSection')) + "\n          ")]) : _vm._e(), _vm._v(" "), _vm.enableWowlinkTypeFieldTexts.length ? [_c('div', {
    staticClass: "d-flex mb-2"
  }, [_c('IconsSalesforce'), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 ml-1"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('extension.wowLinkSyncCase')) + "\n              ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mb-1"
  }, [_vm._v("\n              " + _vm._s(_vm.canCreateOrder ? _vm.$t('extension.wowLinkSyncDescCampaign', [_vm.enableWowlinkTypeFieldTexts.join(_vm.$t('comma'))]) : _vm.$t('extension.wowLinkSyncDescCampaignNeedApprove', [_vm.enableWowlinkTypeFieldTexts.join(_vm.$t('comma'))])) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption secondary-text--text mb-4"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkSyncHelp1')) + "\n              "), _c('LinkText', {
    attrs: {
      "to": "https://sendwow.notion.site/WOW-fd408f2a2caf4a328af89e8bad6b411b",
      "text": _vm.$t('extension.wowLinkSyncHelp2')
    }
  }), _vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkSyncHelp3')) + "\n            ")], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex mb-2"
  }, [_c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-content-copy")]), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 ml-1"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('extension.wowLinkCopyCase')) + "\n              ")])], 1)] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n            " + _vm._s(_vm.canCreateOrder ? _vm.$t('extension.wowLinkListDesc') : _vm.$t('extension.wowLinkListDescNeedApprove')) + "\n          ")]), _vm._v(" "), (_vm$data5 = _vm.data) !== null && _vm$data5 !== void 0 && _vm$data5.campaignId ? _c('TextButton', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.$t('action.doCheckCampaignDetail')
    },
    on: {
      "click": function click($event) {
        var _vm$data6;
        return _vm.$open("/campaign/".concat((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.campaignId));
      }
    }
  }) : _vm._e()] : _vm._e(), _vm._v(" "), (_vm$data7 = _vm.data) !== null && _vm$data7 !== void 0 && _vm$data7.isDigitalGift && _vm.existContacts ? _c(VDivider, {
    staticClass: "my-6"
  }) : _vm._e(), _vm._v(" "), _vm.existContacts ? [(_vm$data8 = _vm.data) !== null && _vm$data8 !== void 0 && _vm$data8.isDigitalGift ? _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": 2
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('extension.tagSection')) + "\n          ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n            " + _vm._s((_vm$data9 = _vm.data) !== null && _vm$data9 !== void 0 && _vm$data9.isDigitalGift ? _vm.$t('extension.tagDescriptionWithWowLink') : _vm.$t('extension.tagDescriptionWithDelivery')) + "\n          ")]), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('tag')
    }
  }), _vm._v(" "), _c('TagSelect', {
    attrs: {
      "value": _vm.form.tags,
      "items": _vm.tags
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.form.tags = $event;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mt-4",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    on: {
      "click": _vm.onClickSave
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('action.doAdd')) + "\n            ")])])] : _vm._e()], 2)], _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary mt-6",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.close')) + "\n    ")])], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };